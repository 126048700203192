import React from 'react';

export const ProfileIcon = () => (
    <svg
        data-testid="profile-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 11C3 7.22876 3 5.34315 4.17157 4.17157C5.34315 3 7.22876 3 11 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H11C7.22876 21 5.34315 21 4.17157 19.8284C3 18.6569 3 16.7712 3 13V11Z"
            fill="#003c6d"
            fillOpacity="0.6"
        />
        <circle cx="12" cy="10" r="4" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9468 20.2532C18.9621 20.3587 18.9053 20.4613 18.8068 20.5021C17.6053 21 15.8358 21 13.0005 21H11.0005C8.16525 21 6.39579 21 5.19426 20.5022C5.09579 20.4614 5.03894 20.3587 5.05422 20.2532C5.48314 17.2919 8.42957 15 12.0005 15C15.5715 15 18.5179 17.2919 18.9468 20.2532Z"
            fill="white"
        />
    </svg>
);

export const LogoutIcon = ({ color = '#003c6d' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 7.13193V6.61204C7 4.46614 7 3.3932 7.6896 2.79511C8.37919 2.19703 9.44136 2.34877 11.5657 2.65224L15.8485 3.26408C18.3047 3.61495 19.5327 3.79039 20.2664 4.63628C21 5.48217 21 6.72271 21 9.20377V14.7962C21 17.2773 21 18.5178 20.2664 19.3637C19.5327 20.2096 18.3047 20.385 15.8485 20.7359L11.5657 21.3478C9.44136 21.6512 8.37919 21.803 7.6896 21.2049C7 20.6068 7 19.5339 7 17.388V17.066"
            stroke={color}
            strokeWidth="2"
            fillOpacity="0.6"
        />
        <path
            d="M16 12L16.7809 11.3753L17.2806 12L16.7809 12.6247L16 12ZM4 13C3.44771 13 3 12.5523 3 12C3 11.4477 3.44771 11 4 11V13ZM12.7809 6.3753L16.7809 11.3753L15.2191 12.6247L11.2191 7.6247L12.7809 6.3753ZM16.7809 12.6247L12.7809 17.6247L11.2191 16.3753L15.2191 11.3753L16.7809 12.6247ZM16 13H4V11H16V13Z"
            fill={color}
            fillOpacity="0.6"
        />
    </svg>
);
