import React from 'react';
import { ChevronDownIcon } from '../icons';
import { Skeleton, Box, Flex } from '@chakra-ui/react';
import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import onGetClientScreen from '@store/clientUser';

const NavbarSkeleton = () => {
    const clientScreen = onGetClientScreen();
    const skeletonSize = clientScreen === 'wideScreen' ? '22px' : clientScreen === 'mediumScreen' ? '18px' : '18px';
    const skeletonParentWidth =
        clientScreen === 'wideScreen' ? '160px' : clientScreen === 'mediumScreen' ? '136px' : '140px';
    const skeletonChildWidth =
        clientScreen === 'wideScreen' ? '180px' : clientScreen === 'mediumScreen' ? '156px' : '156px';

    return (
        <Box className="navbar-skeleton__container">
            <IterationComponent
                arr={[1, 2, 3, 4]}
                render={(index) => (
                    <Box className="navbar-item__skeleton">
                        <Flex className="parent">
                            <Skeleton
                                height={skeletonSize}
                                w={skeletonSize}
                                borderRadius="50%"
                                startColor="var(--main-color-light-active)"
                                endColor={`rgba(var(--main-color-light-active),0.5)`}
                            />
                            <Skeleton
                                height={skeletonSize}
                                w={skeletonParentWidth}
                                borderRadius="8px"
                                startColor="var(--main-color-light-active)"
                                endColor={`rgba(var(--main-color-light-active),0.5)`}
                            />
                            <ChevronDownIcon color="var(--main-color)" />
                        </Flex>
                        <ConditionalComponent statement={index === 3}>
                            <IterationComponent
                                arr={[1, 2, 3]}
                                render={() => (
                                    <Flex className="child">
                                        <Skeleton
                                            height={skeletonSize}
                                            w={skeletonChildWidth}
                                            borderRadius="8px"
                                            startColor="var(--main-color-light-active)"
                                            endColor={`rgba(var(--main-color-light-active),0.5)`}
                                        />
                                    </Flex>
                                )}
                            />
                        </ConditionalComponent>
                    </Box>
                )}
            />
        </Box>
    );
};

export default NavbarSkeleton;
