import { Image } from '@chakra-ui/react';
import React from 'react';
import upload from './upload.svg';
import calendar from './calendar.svg';
import excel from './excel.svg';
import questionBlue from './question-blue.svg';

export const UploadIcon = () => <Image src={upload} />;
export const CalendarIcon = () => <Image src={calendar} />;
export const ExcelIcon = () => <Image src={excel} />;
export const QuestionBlueIcon = () => <Image src={questionBlue} />;
