import { find } from 'lodash';

export const fetchGlobalStyles = (environment) => {
    environment === 'development' ? require('../@styles/development-styles') : require('../@styles/production-styles');

    return environment;
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const filteringRouteByMenuAccess = (route, menuAccess) => {
    return route?.filter(({ id, type }) => find(menuAccess, { link: id })?.link || type === '*');
};
