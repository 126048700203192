import Cookies from 'universal-cookie';
import Axios from '@services/axios';
import { saveAs } from 'file-saver';
import queryString from 'qs';
import { isArray } from 'lodash';
import { assetFormatting, onDetailStorePayloadFormatting, templateFormatting } from './utils';

export const fetchStoreConfig = async ({ onChangeStoreConfigStore, selectedFilterStore }) => {
    const {
        page,
        selectedCustomer,
        selectedSalesArea,
        selectedDC,
        storeConfigStatus,
        storeConfigSystem,
        storeConfigConsignment,
    } = selectedFilterStore;

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const filterStringify = queryString.stringify(
        {
            page,
            limit: 7,
            system_type: storeConfigSystem?.length ? storeConfigSystem[0]?.id : null,
            customer: selectedCustomer?.length ? `[${selectedCustomer?.map((item) => item?.id)}]` : [],
            dc: selectedDC?.length ? `[${selectedDC?.map((item) => item?.name)}]` : [],
            status: storeConfigStatus?.length ? `${storeConfigStatus[0]?.id}` : '',
            consignment: storeConfigConsignment?.length ? `${storeConfigConsignment[0]?.id}` : '',
            sales_area: selectedSalesArea?.length ? `[${selectedSalesArea?.map((item) => item?.name)}]` : [],
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    onChangeStoreConfigStore({ isLoadingTable: true });

    try {
        const { data } = await Axios({
            url: `/api/v1/configuration/customer-list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeStoreConfigStore({
            storeConfigs: data?.data?.data,
            page,
            paginationInfo: {
                dataCount: data?.data?.data?.length,
                totalData: data?.data?.total,
                totalPage: data?.data?.total / 10,
            },
            isLoadingTable: false,
        });
    } catch (err) {
        console.log(err, '<<<');
        onChangeStoreConfigStore({
            storeConfigs: [],
            page: 0,
            paginationInfo: {
                dataCount: 0,
                totalData: 0,
                totalPage: 0,
            },
            isLoadingTable: false,
        });
    }
};

export const onDownloadStoreConfig = async ({ store, onChangeDownloadNotification }) => {
    try {
        const { selectedCustomer, selectedDC, storeConfigSystem, storeConfigStatus, storeConfigConsignment } = store;
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const filterStringify = queryString.stringify(
            {
                system_type: storeConfigSystem?.length ? storeConfigSystem[0]?.id : null,
                customer: `[${selectedCustomer?.map((item) => item?.id)}]`,
                dc: `[${selectedDC?.map((item) => item?.name)}]`,
                status: storeConfigStatus?.length ? `${storeConfigStatus[0]?.id}` : '',
                consignment: storeConfigConsignment?.length ? `${storeConfigConsignment[0]?.id}` : '',
            },
            { skipEmptyString: true, arrayFormat: 'separator' }
        );

        onChangeDownloadNotification({
            status: 'download',
            title: 'Downloading',
            description: 'Harap tunggu, sedang mendownload store_config.xlsx',
        });

        const { data } = await Axios({
            method: 'GET',
            url: `/api/v1/configuration/download/list?${filterStringify}`,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });

        const blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, `store_config.xlsx`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Download Success',
            description: 'File  store_config.xlsx berhasil di download',
            duration: 5,
        });
    } catch (error) {
        console.log(error);
        const message =
            error?.response?.data?.message ||
            'fitur export hanya bisa digunakan jika setidaknya memilih dc, dan maksimal hanya 2 DC  atau menggunakan filter system';
        onChangeDownloadNotification({
            status: 'error',
            title: 'Download  store_config.xlsx failed',
            description: message,
            duration: 1000,
        });
    }
};

//============================ FETCH ASSETS ============================

export const fetchSystem = async ({ onChangeFilterSystemOptionStore }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterSystemOptionStore({ systemOptions: [], isLoadingSystemOption: true });

    try {
        const { data } = await Axios({
            method: 'GET',
            url: '/api/v1/system-type/list',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data;
        onChangeFilterSystemOptionStore({ systemOptions: result, isLoadingSystemOption: false });
    } catch (error) {
        console.log(error);
        onChangeFilterSystemOptionStore({ systemOptions: [], isLoadingSystemOption: false });
    }
};

export const fetchCustomer = async ({ onChangeFilterCustomerOptionStore = () => null, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeFilterCustomerOptionStore({ isLoadingCustomerOption: true, customerOptions: [] });

    const filterStringify = queryString.stringify(
        {
            page: 1,
            limit: 20,
            searchparam: searchQuery ? `nama_outlet.${searchQuery},customer_id.${searchQuery}` : '',
            dc: `[]`,
        },
        { skipEmptyString: true, arrayFormat: 'separator' }
    );

    try {
        const { data } = await Axios({
            url: `/api/v1/user/customer-dc?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                store_name: item?.nama_outlet,
                name: `${item?.nama_outlet} (${item?.customer_id})`,
                id: item?.customer_id,
            };
        });

        onChangeFilterCustomerOptionStore({ customerOptions: result, isLoadingCustomerOption: false });

        return result;
    } catch (error) {
        console.log(error);
        onChangeFilterCustomerOptionStore({ isLoadingCustomerOption: false, customerOptions: [] });
    }
};
export const fetchStoreList = async ({
    isRefetch,
    page,
    searchQuery,
    onChangeStoreConfigAssetsStore,
    currentData,
    limit = 20,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const filterStringify = queryString.stringify(
        { page, limit, searchparam: `group.${searchQuery}` },
        { skipEmptyString: true }
    );

    if (isRefetch) onChangeStoreConfigAssetsStore({ isLoadingStoreGroupOption: true });

    try {
        const { data } = await Axios({
            url: `/api/v1/group-store/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const result = data?.data?.page_data;

        const storeConfigStoreGroupOption = page === 1 ? result : [...currentData, ...result];

        if (isRefetch) {
            onChangeStoreConfigAssetsStore({
                storeConfigStoreGroupOption,
                isLoadingStoreGroupOption: false,
                totalPageStoreGroupOption: data?.data?.total_page,
            });
        }

        return { options: result, totalPage: data?.data?.total_page };
    } catch (error) {
        console.log(error);
        if (isRefetch)
            onChangeStoreConfigAssetsStore({
                storeConfigStoreGroupOption: [],
                isLoadingStoreGroupOption: false,
                totalPageStoreGroupOption: 0,
            });

        return [];
    }
};

export const fetchSalesArea = async ({ onChangeFilterSalesAreaOptionStore, searchQuery }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const filterStringify = queryString.stringify(
        { limit: 20, searchparam: `name.${searchQuery}` },
        { skipEmptyString: true }
    );

    onChangeFilterSalesAreaOptionStore({ salesAreaOptions: [], isLoadingSalesAreaOption: true });

    try {
        const { data } = await Axios({
            url: `/api/v1/sales-area/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const result = data?.data?.page_data;

        onChangeFilterSalesAreaOptionStore({ salesAreaOptions: result, isLoadingSalesAreaOption: false });
    } catch (error) {
        console.log(error);

        onChangeFilterSalesAreaOptionStore({ salesAreaOptions: [], isLoadingSalesAreaOption: false });
    }
};

export const fetchDC = async ({ searchQuery, onChangeFilterDCOptionStore }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    const filterStringify = queryString.stringify(
        { searchparam: searchQuery && `dc_name.${searchQuery}` },
        { skipEmptyString: true }
    );
    onChangeFilterDCOptionStore({ dcOptions: [], isLoadingDCOption: true });

    try {
        const { data } = await Axios({
            url: `/api/v1/dc/list?${filterStringify}`,
            method: 'GET',
            headers: { Authorization: 'Bearer ' + userToken },
        });

        let result = data?.data?.page_data;

        result = result?.map((item) => {
            return {
                ...item,
                name: item?.dc_name,
            };
        });

        onChangeFilterDCOptionStore({ dcOptions: result, isLoadingDCOption: false });
    } catch (error) {
        console.log(error);
        onChangeFilterDCOptionStore({ dcOptions: [], isLoadingDCOption: false });
    }
};

//========================= UPLOAD FUNCTION ===========================

export const onUploadFile = async ({
    onChangePopUp,
    selectedFile,
    onChangeSnackbarNotification,
    onFetchStoreConfig,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    try {
        onChangePopUp({ isLoading: true });

        const formData = new FormData();

        formData.append('file', selectedFile);

        await Axios({
            url: `/api/v1/configuration/import`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Success',
            description: 'Berhasil upload file',
            duration: 5,
            detailMessage: [],
            alwaysShow: false,
        });

        onFetchStoreConfig();
    } catch (error) {
        console.log(error);

        const errorMessage = error?.response?.data?.message;
        const errorTitle = error?.response?.data?.title;

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Failed',
            description: errorTitle ? errorTitle : isArray(errorMessage) ? 'Gagal upload file' : errorMessage,
            detailMessage: isArray(errorMessage) ? errorMessage : [],
            alwaysShow: isArray(errorMessage),
            duration: 5,
        });
    } finally {
        onChangePopUp({ isLoading: false, status: null });
    }
};

//========================================== DETAIL ============================================================

export const fetchStoreInfo = async ({ onChangeState, id }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const { data } = await Axios({
            method: 'GET',
            url: `/api/v1/configuration/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeState({
            isLoading: false,
            storeInfo: {
                ...data?.data,
                latestUpdate: data?.latestUpdate,
            },
        });
    } catch (error) {
        console.log(error);
        onChangeState({
            isLoading: false,
        });
    }
};

export const fetchAssetDetail = async ({ onChangeAssetState }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const templateList = await Axios({
            method: 'GET',
            url: `/api/v1/template-upload/list`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { data } = await Axios({
            method: 'GET',
            url: `/api/v1/system-type`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        onChangeAssetState({
            systemList: assetFormatting({ data: data?.data }),
            templateList: templateFormatting({ data: templateList?.data?.data }),
        });
    } catch (error) {
        console.log(error);
    }
};

export const onDownloadHistory = async ({ id, onChangeDownloadNotification }) => {
    const fileName = 'store config history';

    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        onChangeDownloadNotification({
            status: 'download',
            title: 'Downloading',
            description: `Harap tunggu, sedang mendownload "${fileName}.xlx"`,
        });

        const { data } = await Axios({
            method: 'GET',
            responseType: 'blob',
            url: `/api/v1/configuration/download/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const blob = new Blob([data], { type: 'text/xlsx' });
        saveAs(blob, `store config history.xlsx`);

        onChangeDownloadNotification({
            status: 'success',
            title: 'Success',
            description: `Download "${fileName}.xlsx" berhasil`,
        });
    } catch (error) {
        console.log(error);

        onChangeDownloadNotification({
            status: 'error',
            title: 'Failed',
            description: `Download "${fileName}.xlsx" gagal`,
        });
    }
};

export const onSubmit = async ({
    storeInfo,
    id,
    onFetchData,
    onChangeState,
    onChangeSnackbarNotification,
    isBackgroundProgress,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        if (isBackgroundProgress) {
            onChangeSnackbarNotification({
                status: 'failed',
                title: '    ',
                description: 'Tidak dapat melakukan perubahan ketika sync to exagon berjalan!',
                duration: 5,
            });

            return;
        }

        onChangeState({ isLoading: true });

        const payload = onDetailStorePayloadFormatting({ storeInfo });

        await Axios({
            method: 'PUT',
            url: `/api/v1/configuration/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payload,
        });

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Succes',
            description: 'Berhasil mengubah data',
            duration: 5,
        });

        await onFetchData();
    } catch (error) {
        console.log(error);

        const errorMessage = error?.response?.data?.message || 'Gagal mengupdate config!';

        onChangeSnackbarNotification({
            status: 'error',
            title: errorMessage,
            description: errorMessage,
            duration: 5,
        });
    } finally {
        onChangeState({ isLoading: false });
    }
};

//================================== SYNC ===================================
export const onFetchSyncToExagon = async ({ id, setSyncData, endpointGet }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        const { data } = await Axios({
            method: 'GET',
            url: `/api/v1/${endpointGet}/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        setSyncData(data?.data);
    } catch (error) {
        setSyncData({});
    }
};

export const onForceRunPost = async ({
    id,
    setIsOpenModal,
    onChangeSnackbarNotification,
    fetchSyncToExagon,
    forceRunEndpoint,
}) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');

        setIsOpenModal(true);

        await Axios({
            method: 'POST',
            url: `/api/v1/${forceRunEndpoint}/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        fetchSyncToExagon();
    } catch (error) {
        console.log(error);

        const errorMessage = error?.response?.data?.message || 'Something wrong';

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Gagal dapat melakukan force run',
            description: errorMessage,
            duration: 5,
        });
        setIsOpenModal(false);
    }
};
