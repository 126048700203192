import { Text } from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { ActionsComponent, IconChecked, Status, TextColumn } from '@pages/StoreConfig/components/columns';

export const tableList = [
    {
        name: 'Customer Id',
        value: 'customer_id',
        width: 130,
        component: ({ value }) => <TextColumn value={value} />,
    },
    {
        name: 'Nama Toko',
        value: 'nama_outlet',
        width: 300,
        component: ({ value }) => <TextColumn value={value} />,
    },
    {
        name: 'DC',
        value: 'nama_dc',
        width: 140,
        component: ({ value }) => <TextColumn value={value} />,
    },

    {
        name: 'Sales Area',
        value: 'sales_area',
        width: 140,
        component: ({ value }) => <TextColumn value={value} />,
    },
    {
        name: 'Status',
        value: 'is_active',
        width: 100,
        component: ({ value }) => <Status value={value} />,
    },
    {
        name: 'Consignment',
        value: 'is_sync_consignment',
        width: 140,
        component: ({ value }) => (
            <Text className="column-text">
                <IconChecked value={value} />
            </Text>
        ),
    },

    {
        name: 'Bivi Sync',
        value: 'is_sync_bivi_dashboard',
        width: 100,
        component: ({ value }) => (
            <Text className="column-text">
                <IconChecked value={value} />
            </Text>
        ),
    },
    {
        name: 'Template',
        value: 'template_upload',
        width: 200,
        component: ({ value }) => <TextColumn value={value} />,
    },

    {
        name: 'Last Update',
        value: 'updated_at',
        width: 200,
        component: ({ value }) => <TextColumn value={value && moment(value)?.format('DD MMM YYYY')} />,
    },
    {
        name: 'Action',
        value: 'customer_id',
        width: 100,
        component: ({ value }) => {
            return <ActionsComponent value={value} />;
        },
    },
];
