import { Skeleton } from '@chakra-ui/react';
import { ConditionalComponent } from '@components/functionComponent';
import React from 'react';

const SkeletonContainer = ({ isLoading, children, height, width, color, name }) => (
    <>
        <ConditionalComponent statement={isLoading}>
            <Skeleton
                data-testid={`skeleton-container__global__${name}`}
                w={width}
                h={height}
                startColor={color}
                endColor={`rgba(#${color},0.5)`}
            />
        </ConditionalComponent>
        <ConditionalComponent statement={!isLoading}>{children}</ConditionalComponent>
    </>
);

export default SkeletonContainer;
