import React, { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Countdown from 'react-countdown';

import { ConditionalComponent, IterationComponent } from '@components/functionComponent';
import { useSnackbarNotification } from '@store/snackbarNotification';

import { CloseIcon } from './icons';
import { Icon, getColor } from './const';

import './styles.scss';
import { isArray } from 'lodash';

const SnackbarNotification = () => {
    const { status, title, description, duration, onResetSnackbarNotification, useCounter, detailMessage, alwaysShow } =
        useSnackbarNotification();

    const { borderColor, backgroundColor, iconColor } = getColor({ status });
    useEffect(() => {
        setTimeout(() => {
            if (status && !alwaysShow) {
                onResetSnackbarNotification();
            }
        }, [duration * 1000]);
        // eslint-disable-next-line
    }, [status, detailMessage, title, description]);

    return (
        <ConditionalComponent statement={!!status}>
            <Box className="snackbar-notification__container">
                <Flex className="box" bg={backgroundColor} border={`1px solid ${borderColor}`}>
                    <Icon color={iconColor} status={status} />
                    <Box className="info-container">
                        <Text className="title">{title}</Text>
                        <Text className="description">
                            {description}
                            <ConditionalComponent statement={useCounter}>
                                <Countdown
                                    date={Date.now() + duration * 1000}
                                    renderer={({ seconds }) => <span>{seconds}</span>}
                                />
                                detik
                            </ConditionalComponent>
                        </Text>

                        <ConditionalComponent statement={isArray(detailMessage) && detailMessage?.length}>
                            <Box className="detail-message__container">
                                <IterationComponent
                                    arr={detailMessage}
                                    render={(item) => <Text className="detail-message">{item}</Text>}
                                />
                            </Box>
                        </ConditionalComponent>
                    </Box>
                    <CloseIcon color={iconColor} onClick={onResetSnackbarNotification} />
                </Flex>
            </Box>
        </ConditionalComponent>
    );
};

export default SnackbarNotification;
