import React from 'react';
import { Text } from '@chakra-ui/react';
import './styles.scss';

export const TextRegular = ({ className, children }) => (
    <Text className={`${className} sagittarius-text__reguler`}>{children}</Text>
);
export const TextMedium = ({ className, children }) => (
    <Text className={`${className} sagittarius-text__medium`}>{children}</Text>
);
export const TextSemiBold = ({ className, children }) => (
    <Text className={`${className} sagittarius-text__semibold`}>{children}</Text>
);
export const TextBold = ({ className, children }) => (
    <Text className={`${className} sagittarius-text__bold`}>{children}</Text>
);
export const TextExtraBold = ({ className, children }) => (
    <Text className={`${className} sagittarius-text__extrabold`}>{children}</Text>
);
