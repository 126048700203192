import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { onCheckIsLogin, onDragFile } from '@helpers/events';
import { ConditionalComponent } from '@components/functionComponent';
import { Box, Flex } from '@chakra-ui/react';
import Navbar from '@components/navbar';
import Header from '@components/header';
import SnackbarNotification from '@components/snackbarNotification';
import DownloadNotification from '@components/downloadNotification';
import PopUp from '@components/popUp';
import SpotLight from '@components/spotlight';
import DashboardSkeleton from '@components/dashboardSkeleton';
import SplashScreen from '@components/splash';
import Loader from '@components/loader';
import { useDragFile } from '@store/dragFile';
import { useUserInfo } from '@store/userInfo';

import { onFetchProfile } from './helpers/crud';
import { onCheckIsDashboardPage } from './helpers/utils';

import './styles.scss';

const DashboardContainer = ({ children }) => {
    const cookies = new Cookies();
    const token = cookies.get('userToken');
    const { onChangeUserInfo } = useUserInfo();
    const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(false);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isDashboardPage = onCheckIsDashboardPage({ pathname });
    const { onChangeDragFile } = useDragFile();

    useEffect(() => {
        onCheckIsLogin({ token, navigate, pathname });
        // eslint-disable-next-line
    }, [token, pathname]);

    useEffect(() => {
        if (token) onFetchProfile({ token, navigate, pathname, setIsLoadingUserProfile, onChangeUserInfo });
        // eslint-disable-next-line
    }, [token]);

    return (
        <Flex
            className="dashboard-container"
            onDragLeave={(event) => onDragFile({ event, type: 'leave', onChangeDragFile })}
            onDragEnter={(event) => onDragFile({ event, type: 'enter', onChangeDragFile })}
        >
            {/* ============== IF DASHBOARD PAGE ============== */}

            <ConditionalComponent statement={isDashboardPage}>
                <Box className="left-section__dashboard" data-testid="left-section__dashboard">
                    <Navbar isLoadingUserProfile={isLoadingUserProfile} />
                </Box>
                <Box className="right-section__dashboard" data-testid="right-section__dashboard">
                    <Header />
                    <ConditionalComponent statement={!isLoadingUserProfile} fallback={<DashboardSkeleton />}>
                        <Box className="content-box__container">{children}</Box>
                    </ConditionalComponent>
                </Box>
            </ConditionalComponent>

            {/* ============== IF NOT DASHBOARD PAGE ============== */}

            <ConditionalComponent statement={!isDashboardPage && !isLoadingUserProfile}>
                {children}
            </ConditionalComponent>

            {/* //================== GLOBAL ASSETS ============================= */}

            <ConditionalComponent statement={!isDashboardPage && isLoadingUserProfile && !pathname?.includes('/oauth')}>
                <Loader />
            </ConditionalComponent>

            {/* //================== GLOBAL ASSETS ============================= */}

            <ConditionalComponent statement={!pathname?.includes('/oauth')}>
                <SplashScreen />
            </ConditionalComponent>

            <SnackbarNotification />
            <DownloadNotification />
            <PopUp />
            <SpotLight />
            <Loader />
        </Flex>
    );
};

export default DashboardContainer;
