import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onResetPasswordHandler = async ({
    confirmationPassword,
    password,
    onChangeSnackbarNotification,
    isValid,
    onChangeResetPasswordStore,
    navigate,
}) => {
    if (!isValid) return;

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeResetPasswordStore({ isLoading: true });

    try {
        //============================ RESET ========================

        await Axios({
            url: `/api/v1/account/reset`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                password: `pass-id-${Math.random() * 10000}`,
                new_password: password,
                confirm_password: confirmationPassword,
            },
        });

        //============================ SET NOTIF ========================

        onChangeSnackbarNotification({
            status: 'success',
            title: 'Reset Password berhasil',
            description: 'Berhasil masuk ke halaman dashboard dalam',
            duration: 2,
            useCounter: true,
        });

        //============================ NAVIGATE ========================

        setTimeout(() => navigate('/'), 2000);
    } catch (err) {
        console.log(err);

        const errorMessage = err?.response?.data?.message || 'Something wrong!';

        onChangeSnackbarNotification({
            status: 'error',
            title: 'Reset Password gagal',
            description: errorMessage,
            duration: 5,
        });

        onChangeResetPasswordStore({ isLoading: false });
    }
};
