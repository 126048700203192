import React from 'react';

export const CheckIcon = ({ active }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1393_1002)">
            <path
                d="M9.99996 18.3333C12.3011 18.3333 14.3845 17.4006 15.8925 15.8925C17.4005 14.3845 18.3333 12.3012 18.3333 10C18.3333 7.69884 17.4005 5.6155 15.8925 4.10745C14.3845 2.59941 12.3011 1.66667 9.99996 1.66667C7.69879 1.66667 5.61546 2.59941 4.1074 4.10745C2.59937 5.6155 1.66663 7.69884 1.66663 10C1.66663 12.3012 2.59937 14.3845 4.1074 15.8925C5.61546 17.4006 7.69879 18.3333 9.99996 18.3333Z"
                fill={active ? '#2EA31F' : '#868686'}
                stroke={active ? '#2EA31F' : '#868686'}
                strokeWidth="1.66667"
                strokeLinejoin="round"
            />
            <path
                d="M6.66663 10L9.16663 12.5L14.1666 7.5"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1393_1002">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const OutlookIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3786_44788)">
            <path
                d="M20 10.6006C20.0011 10.4442 19.9203 10.2987 19.787 10.2169H19.7846L19.7763 10.2123L12.846 6.10993C12.8161 6.08971 12.785 6.07123 12.753 6.05459C12.4854 5.91654 12.1676 5.91654 11.9 6.05459C11.8679 6.07124 11.8369 6.08971 11.807 6.10993L4.87674 10.2123L4.86838 10.2169C4.65657 10.3486 4.59163 10.6271 4.72335 10.8389C4.76216 10.9013 4.81561 10.9533 4.87907 10.9904L11.8093 15.0927C11.8393 15.1128 11.8704 15.1312 11.9023 15.1481C12.1699 15.2861 12.4878 15.2861 12.7554 15.1481C12.7873 15.1312 12.8183 15.1128 12.8484 15.0927L19.7786 10.9904C19.917 10.9097 20.0016 10.7609 20 10.6006Z"
                fill="#0A2767"
            />
            <path
                d="M5.68463 7.86162H10.2325V12.0304H5.68463V7.86162ZM19.0697 3.62395V1.71697C19.0807 1.24018 18.7033 0.844677 18.2265 0.833252H6.42464C5.94785 0.844677 5.57045 1.24018 5.58138 1.71697V3.62395L12.5581 5.48441L19.0697 3.62395Z"
                fill="#0364B8"
            />
            <path d="M5.58139 3.62402H10.2325V7.81007H5.58139V3.62402Z" fill="#0078D4" />
            <path
                d="M14.8837 3.62402H10.2325V7.81007L14.8837 11.9961H19.0698V7.81007L14.8837 3.62402Z"
                fill="#28A8EA"
            />
            <path d="M10.2325 7.81006H14.8837V11.9961H10.2325V7.81006Z" fill="#0078D4" />
            <path d="M10.2325 11.996H14.8837V16.182H10.2325V11.996Z" fill="#0364B8" />
            <path d="M5.68463 12.0304H10.2325V15.8202H5.68463V12.0304Z" fill="#14447D" />
            <path d="M14.8837 11.996H19.0697V16.182H14.8837V11.996Z" fill="#0078D4" />
            <path
                d="M19.787 10.9649L19.7781 10.9695L12.8479 14.8672C12.8177 14.8858 12.787 14.9035 12.7549 14.9193C12.6372 14.9754 12.5093 15.007 12.3791 15.0123L12.0004 14.7909C11.9685 14.7749 11.9374 14.757 11.9074 14.7374L4.88417 10.7291H4.88092L4.65115 10.6007V18.4909C4.65474 19.0174 5.08426 19.4413 5.61068 19.4379H19.0553C19.0632 19.4379 19.0702 19.4342 19.0786 19.4342C19.1898 19.4271 19.2994 19.4042 19.4042 19.3663C19.4494 19.3471 19.4931 19.3244 19.5349 19.2984C19.566 19.2807 19.6195 19.2421 19.6195 19.2421C19.8578 19.0658 19.9988 18.7874 20 18.4909V10.6007C19.9998 10.7516 19.9184 10.8908 19.787 10.9649Z"
                fill="url(#paint0_linear_3786_44788)"
            />
            <path
                opacity="0.5"
                d="M19.6279 10.5696V11.0534L12.3814 16.0427L4.87906 10.7324C4.87906 10.7299 4.87697 10.7278 4.8744 10.7278L4.18604 10.3138V9.96498L4.46976 9.96033L5.06975 10.3045L5.0837 10.3092L5.13486 10.3417C5.13486 10.3417 12.186 14.365 12.2046 14.3743L12.4744 14.5324C12.4976 14.5231 12.5209 14.5138 12.5488 14.5045C12.5628 14.4952 19.5488 10.565 19.5488 10.565L19.6279 10.5696Z"
                fill="#0A2767"
            />
            <path
                d="M19.7869 10.9649L19.7781 10.97L12.8479 14.8677C12.8176 14.8863 12.787 14.9039 12.7549 14.9198C12.4857 15.0512 12.171 15.0512 11.9018 14.9198C11.87 14.904 11.8389 14.8866 11.8088 14.8677L4.87859 10.97L4.87023 10.9649C4.73643 10.8923 4.65254 10.7529 4.65115 10.6007V18.4909C4.65448 19.0173 5.08386 19.4412 5.61018 19.4379C5.61018 19.4379 5.6102 19.4379 5.61022 19.4379H19.0409C19.5672 19.4413 19.9966 19.0173 20 18.491C20 18.491 20 18.491 20 18.4909V10.6007C19.9998 10.7516 19.9184 10.8908 19.7869 10.9649Z"
                fill="#1490DF"
            />
            <path
                opacity="0.1"
                d="M12.9488 14.8099L12.8451 14.8681C12.815 14.8872 12.784 14.9048 12.7521 14.9206C12.6378 14.9767 12.5136 15.0096 12.3865 15.0174L15.0232 18.1355L19.6228 19.2439C19.7488 19.1487 19.8491 19.0235 19.9144 18.8797L12.9488 14.8099Z"
                fill="black"
            />
            <path
                opacity="0.05"
                d="M13.4186 14.5459L12.8451 14.8682C12.815 14.8874 12.784 14.9049 12.7521 14.9208C12.6378 14.9769 12.5136 15.0098 12.3865 15.0175L13.6218 18.4236L19.6242 19.2426C19.8606 19.0651 19.9998 18.7867 20 18.491V18.3891L13.4186 14.5459Z"
                fill="black"
            />
            <path
                d="M5.62324 19.4379H19.0395C19.246 19.439 19.4473 19.3737 19.6139 19.2519L12 14.7919C11.968 14.7758 11.9369 14.758 11.907 14.7384L4.8837 10.73H4.88045L4.65115 10.6007V18.464C4.65063 19.0013 5.08585 19.4374 5.62324 19.4379Z"
                fill="#28A8EA"
            />
            <path
                opacity="0.1"
                d="M11.1628 5.40675V15.3277C11.1619 15.6755 10.9504 15.9882 10.6279 16.1184C10.528 16.1613 10.4204 16.1835 10.3116 16.1835H4.65115V5.01931H5.58139V4.5542H10.3116C10.7815 4.55598 11.1618 4.93687 11.1628 5.40675Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M10.6977 5.87188V15.7928C10.6988 15.9052 10.675 16.0164 10.6279 16.1184C10.4987 16.4368 10.1901 16.6456 9.8465 16.6472H4.65115V5.01933H9.8465C9.98146 5.01797 10.1145 5.05164 10.2325 5.11701C10.5177 5.26065 10.6976 5.55263 10.6977 5.87188Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M10.6977 5.87188V14.8626C10.6954 15.3322 10.3162 15.7129 9.84651 15.717H4.65115V5.01933H9.8465C9.98146 5.01797 10.1145 5.05164 10.2325 5.11701C10.5177 5.26065 10.6976 5.55263 10.6977 5.87188Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M10.2325 5.87184V14.8625C10.232 15.3329 9.85178 15.7147 9.38139 15.7169H4.65115V5.01929H9.38138C9.85172 5.01954 10.2328 5.40104 10.2325 5.87138C10.2325 5.87154 10.2325 5.87169 10.2325 5.87184Z"
                fill="black"
            />
            <path
                d="M0.852555 5.01929H9.37999C9.85085 5.01929 10.2325 5.401 10.2325 5.87184V14.3993C10.2325 14.8701 9.85085 15.2518 9.37999 15.2518H0.852555C0.381697 15.2518 0 14.8701 0 14.3993V5.87184C0 5.401 0.381708 5.01929 0.852555 5.01929Z"
                fill="url(#paint1_linear_3786_44788)"
            />
            <path
                d="M2.66465 8.59519C2.87478 8.1475 3.21395 7.77284 3.63861 7.51938C4.1089 7.25013 4.64437 7.1159 5.18606 7.13146C5.68811 7.12057 6.18351 7.24784 6.61815 7.49937C7.0268 7.74307 7.35593 8.10015 7.56559 8.52727C7.79391 8.99792 7.9077 9.51589 7.89768 10.0389C7.90875 10.5855 7.79167 11.1271 7.55582 11.6203C7.34117 12.0627 7.0016 12.4325 6.57908 12.684C6.12768 12.9432 5.61389 13.0739 5.0935 13.0617C4.58072 13.0741 4.0744 12.9454 3.62978 12.6896C3.21758 12.4456 2.88433 12.0881 2.66978 11.6598C2.4401 11.196 2.32495 10.6838 2.33397 10.1663C2.3244 9.62439 2.43744 9.0873 2.66465 8.59519ZM3.70279 11.1208C3.81482 11.4038 4.00481 11.6492 4.2507 11.8287C4.50115 12.0037 4.80105 12.094 5.10651 12.0863C5.43181 12.0992 5.75246 12.0058 6.02 11.8203C6.26277 11.6414 6.44777 11.3953 6.55209 11.1124C6.66871 10.7964 6.72626 10.4617 6.72186 10.1249C6.72546 9.78496 6.67136 9.44683 6.56185 9.12495C6.46513 8.83433 6.2861 8.57807 6.04651 8.38728C5.78568 8.19297 5.46589 8.09441 5.14092 8.10821C4.82885 8.10013 4.52223 8.19114 4.26512 8.36821C4.01507 8.54838 3.82148 8.79602 3.70698 9.08216C3.45297 9.73804 3.45165 10.4649 3.70325 11.1217L3.70279 11.1208Z"
                fill="white"
            />
            <path d="M14.8837 3.62402H19.0697V7.81007H14.8837V3.62402Z" fill="#50D9FF" />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_3786_44788"
                x1="12.3256"
                y1="10.6007"
                x2="12.3256"
                y2="19.4379"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#35B8F1" />
                <stop offset="1" stop-color="#28A8EA" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3786_44788"
                x1="1.7776"
                y1="4.35312"
                x2="8.45495"
                y2="15.918"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#1784D9" />
                <stop offset="0.5" stop-color="#107AD5" />
                <stop offset="1" stop-color="#0A63C9" />
            </linearGradient>
            <clipPath id="clip0_3786_44788">
                <rect width="20" height="18.6047" fill="white" transform="translate(0 0.833374)" />
            </clipPath>
        </defs>
    </svg>
);

export const Circle1Image = () => (
    <svg
        className="circle-1"
        width="312"
        height="316"
        viewBox="0 0 312 316"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle opacity="0.1" cx="240" cy="76" r="192" stroke="white" stroke-width="96" />
    </svg>
);

export const Circle2Image = () => (
    <svg
        className="circle-2"
        width="238"
        height="167"
        viewBox="0 0 238 167"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.1">
            <mask id="path-1-inside-1_3892_142462" fill="white">
                <path d="M237.094 84.7332C211.744 55.0428 179.534 31.9848 143.257 17.5587C106.98 3.13265 67.7309 -2.22587 28.9144 1.94788C-9.90215 6.12162 -47.1145 19.7016 -79.4955 41.5101C-111.876 63.3187 -138.448 92.6973 -156.907 127.098L-82.8893 166.814C-70.8912 144.454 -53.6194 125.357 -32.5717 111.182C-11.5241 97.0064 12.664 88.1794 37.8947 85.4665C63.1254 82.7535 88.6371 86.2366 112.217 95.6135C135.797 104.99 156.734 119.978 173.212 139.277L237.094 84.7332Z" />
            </mask>
            <path
                d="M237.094 84.7332C211.744 55.0428 179.534 31.9848 143.257 17.5587C106.98 3.13265 67.7309 -2.22587 28.9144 1.94788C-9.90215 6.12162 -47.1145 19.7016 -79.4955 41.5101C-111.876 63.3187 -138.448 92.6973 -156.907 127.098L-82.8893 166.814C-70.8912 144.454 -53.6194 125.357 -32.5717 111.182C-11.5241 97.0064 12.664 88.1794 37.8947 85.4665C63.1254 82.7535 88.6371 86.2366 112.217 95.6135C135.797 104.99 156.734 119.978 173.212 139.277L237.094 84.7332Z"
                stroke="white"
                stroke-width="192"
                mask="url(#path-1-inside-1_3892_142462)"
            />
        </g>
    </svg>
);

export const ErrorIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
            fill="#E6001C"
            stroke="#E6001C"
            stroke-width="2"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z"
            fill="white"
        />
        <path d="M12 6V14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
